<template>
  <div class="newUser">
    <div class="container">
      <section class="hero is-fullheight">
        <div class="hero-body pt-4">
          <div class="container">
            <!-- <figure class="image is-48x48 is-pulled-right pt-2 pr-2">
              <img src="/img/Flecha.png" @click="route()"/>
            </figure> -->
            <div class="section box has-background-white-ter pl-6 pr-6 pb-2">
              <div class="section box has-background-grey-lighter pt-4">
                <div class="columns is-mobile">
                  <div class="column">
                    <div class="level">
                      <div class="media-left">
                        <figure class="image is-128x128">
                          <img src="/img/RegistroUsuario.png" />
                        </figure>
                      </div>
                      <div class="media-content">
                        <p class="title is-4 mt-6 is-pulled-left">
                          Registro de Usuarios
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <userForm />
              </div>
              <figure class="image is-48x48">
               <img src="/img/Flecha.png" @click="route()"/>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import userForm from "@/components/userForm.vue";


export default {
  components: {
    userForm,
  },
  data: function () {
    return {
      menu: false,
      url: this.BaseURL,
    };
  },
  methods:{
    
    route(){
      const idp = localStorage.getItem('idp')
      this.$router.push(`/editProject/${idp}`)
    }
  }
};
</script>

<style scoped>
.newUser {
  background-color: #0067e7;
}
</style>
