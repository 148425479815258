<template>
  <div class="row">
    <div class="container">
      <!-- <section class="section"> -->
      <div class="columns is-centered">
        <div class="column is-8 table-container">
          <div class="box has-background-grey-lighter" id="scroll-table">
            <table class="table is-fullwidth has-background-white-ter is-hoverable">
              <tbody v-for="project in projects" :key="project.name">
                <tr>
                  <td>
                    <figure class="image is-pulled-right">
                      <p class="image is-32x32">
                        <img src="/img/Casas.png" />
                      </p>
                    </figure>
                  </td>
                  <td>
                    <h1 class="subtitle is-5 is-pulled-left " v-bind:class="{ 'has-text-danger-dark' : project.enabled == false}">
                      {{ project.name }}
                    </h1>
                  </td>
                  <td>
                    <figure class="image is-32x32 is-pulled-right">
                      
                        <img src="/img/Editar.png" @click="navigate(project.id)" />
                      <!-- </router-link> -->
                    </figure>
                  </td>
                  <td>
                    <figure class="image is-32x32">
                      <img src="/img/Equis.png" @click="delete_project(project.id)" />
                    </figure>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- </section> -->
    </div>
  </div>
</template>

<script>

import Bulma from '@vizuaalog/bulmajs/src/plugins/alert';
import axios from "axios";



export default {
  name: "About",
  data: function () {
    return {
      menu: false,
      projects: [],
      url: this.BaseURL,
      name: "",
    };
  },
  methods: {
    navigate(id){
      this.$router.push({ path: `/editProject/${id}` }) // -> /user/123
    },
    delete_project(id) {
      const project_id = id;
      const y = this;
      Bulma().alert({
      type: 'danger',
      title: 'Borrar Proyecto',
      body: 'Seguro que quiere borrar el proyecto?',
      confirm: {
      label: 'Confirmar',
      onClick: function(){
        axios.delete(y.url+`/projects/${project_id}`).then(() => {
          axios
          .get(y.url+"/users/projects")
          .then((data) => data.data)
          .then((data) => y.projects = data);
      }).catch((err) => {
            console.log("ERROR", err);
            Bulma().alert({
                type: 'warning',
                title: 'Ocurrio un Error',
                body: 'Error al eliminar el proyecto.',
                confirm: 'OK',
            });
          });
      }
    },
    cancel: 'Cancelar'
  });
    },
  },
  mounted() {
    axios
      .get(this.url+"/users/detail")
      .then((res) => res)
      .then((res) => {
        const data = res.data;
        this.projects = data.projects;
        this.name = data.name;
      });
  },
};
</script>

<style scoped>
#scroll-table {
  max-height: 50vh;
  overflow-y: scroll;
}
</style>
