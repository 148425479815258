<template>
  <div class="projectForm">
    <div class="container">
      <!-- <section class="section"> -->
      <div class="columns is-centered">
        <div class="column is-8 table-container">
          <div class="has-background-grey-lighter">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="is-size-6 is-pulled-left">Nombre</label>
                  <div class="control">
                    <input type="text" class="input" name="name" required v-model="form.name" />
                  </div>
                </div>

                <div class="field">
                  <label class="is-size-6 is-pulled-left">MQTTPath</label>
                  <input type="text" class="input" name="mqttpath" required v-model="form.mqttpath"/>
                </div>
                  <br />
              </div>

              <div class="column">
                <div class="field">
                  <label class="is-size-6 is-pulled-left">Usuario</label>
                  <input type="text" class="input  has-text-grey" name="user" readonly v-model="user"/>
                </div>
                <br />
  
              </div>
              
            </div>
              <div class="field">
                  <div class="control">
                    <input
                        class="button is-success"
                        type="submit"
                        value="Guardar Proyecto"
                        @click="save_proyect()"
                      />
                  </div>
                </div>
          </div>
        </div>
      </div>
      <!-- </section> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Bulma from "@vizuaalog/bulmajs/src/plugins/alert"


export default {
  name: "ProjectForm",

  data() {
    return {
      form: {name:"",mqttpath:"",enable:true,user:""},
      project_id: 0,
      user: "",
      url: this.BaseURL,
      type: "",
    };
  },
  methods: {
     save_proyect: function () {
      let name = this.form.name;
      let mqttpath = this.form.mqttpath;
      
     
      if (name != "" && mqttpath !== "") {

        axios
          .post(this.url+"/projects", {
            name: name,
            mqttPath: mqttpath,
            enabled: true,
          })
          .then((resp) => {
            let data = resp.data;
            this.project_id = data.id;
            
            this.$router.push({ path: `/editProject/${data.id}` })
            Bulma().alert({
                type: 'success',
                title: 'Confirmado',
                body: 'El proyecto se guardó exitosamanete.',
                confirm: {
                    label: 'OK'
                },
            });
          })
          .catch((err) => {
            console.log("ERROR", err);
          });
 
      }
    },
  },
    mounted() {
      axios
      .get(this.url+"/users/detail")
      .then((res) => res)
      .then((res) => {
        const name = res.data
        this.user = name.mail
        this.type = name.type;
        if(this.type == "distributor"){
          this.form.enable = false;
        }
      
      });
  },

};
</script>

<style scoped>
#scroll-table {
  max-height: 50vh;
  overflow-y: scroll;
}

</style>
