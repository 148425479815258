<template>
  <div class="projects">
    <!-- <proyectList :proyectos="proyecto" :name="name" /> -->
    <div class="container">
      <section class="hero is-fullheight">
        <div class="hero-body">
          <div class="container">
            <div class="section box has-background-white-ter">
              <div class="columns is-mobile">
                <div class="column">
                  <div class="media">
                    <div class="media-left">
                      <figure class="image is-128x128">
                        <img src="/img/Proyecto.png" />
                      </figure>
                    </div>
                    <div class="media-content">
                      <p class="title is-4 mt-6 is-pulled-left">Proyectos</p>
                    </div>
                  </div>
                </div>
                <div class="column is-hidden-mobile">
                  <figure class="image is-64x64 is-pulled-right">
                    <img src="/img/Aaccesa.png" />
                  </figure>
                </div>
              </div>
              <proyectList :proyectos="proyecto" :name="name" />
              <div class="columns">
                <div class="column">
                  <button
                    class="button is-warning mr-6 pr-4"
                    @click="close()"
                  >
                    Cerrar Sesion
                  </button>
                </div>

                <div class="column">
                  <button
                    class="button is-link mr-6 pr-4"
                    @click="$router.push('/newDistributor')"
                    v-if="type != 'distributor'" v-bind:class="{ 'is-hidden' : type == 'distributor'}"
                  >
                    Alta Distribuidor
                  </button>
                </div>

                <div class="column">
                  <button
                    class="button is-info is-pulled-right mr-6 pr-4"
                    @click="$router.push('/newProject')"
                  >
                    Nuevo Proyecto
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import proyectList from "@/components/proyectList.vue";
import axios from "axios";


export default {
  name: "projects",
  data: function () {
    return {
      type:"",
      project_id:[],
       url: this.BaseURL,
    };
  },
  components: {
    proyectList,
  },
  methods:{
    close: function(){
      localStorage.clear();
      this.$router.push('/')
    }
  },
  mounted(){
    localStorage.removeItem('idp');
    localStorage.removeItem('idpu');

    axios
      .get(this.url+"/users/detail")
      .then((res) => {
        const data = res.data;
        this.type = data.type;
        if(this.type == "owner"){
          this.project_id = data.projects[0].id
          localStorage.setItem('idpu',data.projects[0].id)
          this.$router.push('/NewUserTemp')
        }
        if(this.type == "user"){
          localStorage.clear();
          this.$router.push('/')
        }
      });
  }
};
</script>

<style scoped>
.projects {
  background-color: #0067e7;
}
</style>
