<template>
  <div class="columns">
    <div class="column is-three-quarters">
      <div class="level">
        <div class="level-item">
          <h1 class="subtitle is-4">Zona</h1>
        </div>
        <div class="level-item">
          <input
            type="text"
            class="input"
            v-model="zone_name"
            
          />
        </div>

        <div class="field is-horizontal">
              <div class="field-label is-normal mr-4 ml-4">
                <label class="">Categoria</label>
              </div>
              <div class="field-body">
                <div class="field">
                <div class="select is-pulled-left">
                    <select v-model="zoneCategory">
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636733725/zoneCategory/recamara.jpg">Recamara</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/cocina.jpg">Cocina</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/comedor.jpg">Comedor</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/cochera.jpg">Cochera</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/sala.jpg">Sala</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/estudio.jpg">Estudio</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/oficina.jpg">Oficina</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/baño.jpg">Baño</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/gym.jpg">GYM</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/cuarto_de_juegos.jpg">Cuarto de Juegos</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/cuarto_de_lavado.jpg">Cuarto de Lavado</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/cuarto_de_trabajo.jpg">Cuarto de Trabajo</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/jardín.jpg">Jardín</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/patio.jpg">Patio</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735172/zoneCategory/pasillo.jpg">Pasillo</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/mesanin.jpg">Mesanin</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/terraza.jpg">Terraza</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/roof_garden.jpg">Roof Garden</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/piso.jpg">Piso</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/lobby.jpg">Lobby</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/local.jpg">Local</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/recepción.jpg">Recepción</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/penthouse.jpg">Penthouse</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/estacionamiento.jpg">Estacionamiento</option>
                      <option value="https://res.cloudinary.com/accesa/image/upload/v1636735150/zoneCategory/sala_de_juntas.jpg">Sala de juntas</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
      </div>
      <div class="box has-background-grey-light mb-0" >
       
        <figure class="image is-16by9" id="box" style="position: relative;">
           <div v-for="device in devices" :key="device.id" :style="{width: '25px',height: '25px',background:'blue',borderRadius:'20px',top:device.yPosition + '%' ,left:device.xPosition + '%' ,position:'absolute',zIndex:'30'}"></div>
          <img id="blah" :src="previewImage" alt="" @click="onClickImage($event)" />
        </figure>
      </div>

      <div class="level">
        <!-- <div class="level-left"> -->
        <div class="level-item pt-4">
          <figure class="image is-48x48">
              <img src="/img/Flecha.png" @click="route()"/>
          </figure>
        </div>

        <div class="level-item">
          <figure class="image is-48x48">
            <img src="/img/Eliminar.png" @click="delete_img()"/>
          </figure>
        </div>
        <!-- </div> -->
        <!-- <div class="level-right"> -->
        <div class="level-item">
          <input
            type="text"
            class="input is-small"
            placeholder="X-Y"
            readonly
            v-model="form.xy"
          />
        </div>

        <div class="level-item">
          <button
            class="button is-success"
            @click="save_zone()"
            :disabled="suspend == 0"
          >
            Guardar
          </button>
        </div>
        <div class="level-item">
            <div class="file is-info is-centered">
              <label class="file-label">
                  <input class="file-input" type="file" name="foto" ref="fileInput" @input="pickFile">
                  <span class="file-cta">
                      <span class="file-label">
                          Subir foto
                      </span>
                  </span>
              </label>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <div class="column">
      <h1 class="subtitle is-4">Dispositivos</h1>
      <div class="box has-background-grey-light" id="scroll-table">
        <table class="table is-fullwidth has-background-white-ter is-hoverable">
          <tbody v-for="device in devices" :key="device.name">
            <tr>
              <td>
                <!-- <h1 >{{ proyect.name }}</h1> -->
                <h1 class="subtitle is-6 is-pulled-left">{{ device.name }}</h1>
              </td>
              <!-- <td>
                <figure class="image is-24x24 is-pulled-right">
                  <router-link to="">
                    <img src="/img/Editar.png" />
                  </router-link> 
                </figure>
              </td> -->
              <td>
                <figure class="image is-24x24">
                  <!-- <p class="image is-32x32"> -->
                  <img src="/img/Equis.png" @click="delete_device(device.id)"/>
                  <!-- </p> -->
                </figure>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <button class="button is-info" @click="modal()">Nuevo Dispositivo</button>
    </div>
  </div>
  <div class="modal" :class="{ 'is-active': toggleModal }">
    <div class="modal-background" @click="modal()"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Nuevo Dispositivo</p>
        <button class="delete" aria-label="close" @click="modal()"></button>
      </header>
      <section class="modal-card-body has-background-grey-lighter">
        <div class="columns is-centered">
          <div class="box has-background-white-ter">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="">Nombre</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input" type="text" v-model="form.name"/>
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal mr-4">
                <label class="">Categoria</label>
              </div>
              <div class="field-body">
                <div class="field">
                <div class="select is-pulled-left">
                    <select v-model="form.cat">
                      <option value="light">Luces</option>
                      <option value="dim">Dimeable</option>
                      <option value="rgb">RGB</option>
                      <option value="blind">Persianas</option>
                      <option value="motor">Motores</option>
                      <option value="alarm">Alarma</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>



            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="">Tipo</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="select is-pulled-left">
                    <select v-model="form.type">
                      <option value="light" v-if="form.cat === 'light' || form.cat === 'dim'|| form.cat === 'rgb'">Luces</option>
                      <option value="dim" v-if="form.cat === 'light' || form.cat === 'dim'|| form.cat === 'rgb'">Dimeable</option>
                      <option value="rgb" v-if="form.cat === 'light' || form.cat === 'dim'|| form.cat === 'rgb'">RGB</option>
                      <option value="blind" v-if="form.cat === 'blind'">Persianas</option>
                      <option value="motor" v-if="form.cat === 'motor'">Motores</option>
                      <option value="alarm" v-if="form.cat === 'alarm'">Alarma</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="">Can</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input" type="text" v-model="form.can"/>
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="">Pin</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input" type="text" v-model="form.pin"/>
                  </p>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="">Coordenadas</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input
                      class="input"
                      type="text"
                      readonly
                      placeholder="X-Y"
                      v-model="form.xy"
                    />
                  </p>
                </div>
              </div>
            </div>

            <div class="level pb-2">
              <div class="level-item">
                <figure class="image is-96x96">
                  <img src="/img/Foco.png" alt="" />
                </figure>
              </div>
              <div class="level-item">
                <div class="control">
                  <button class="button is-info" @click="save_device()">Guardar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Bulma from "@vizuaalog/bulmajs/src/plugins/alert"

export default {
  name: "ZoneForm",
  props: ["zid"],

  data: function () {
    return {
      form:{name:"",cat:"",type:"",can:"",pin:"",xy:""},
      toggleModal: false,
      devices: [],
      zone_name: "",
      zone_id:this.zid,
      zoneCategory: '',
      url: this.BaseURL,
      suspend: 1,
      idp: localStorage.getItem('idp'),
      previewImage: "/img/AdjuntarFull.png",
      x: 0,
      y: 0,
       urlCloudinary: this.Cloudinary,
      uploadPreset: this.UploadPreset,
    };
  },
  methods: {
    async save_zone(){
      console.log(this.zone_id)
     this.suspend = 0;
      if(this.zone_name != "" && this.previewImage != "/img/AdjuntarFull.png"){
        let data_photo ={
          file: this.previewImage,
          upload_preset: this.uploadPreset,
          folder: 'zoneImgs',
        }
      await fetch(this.urlCloudinary, {
        method: 'PUT', 
        mode: 'cors', 
        cache: 'no-cache', 
        credentials: 'same-origin', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data_photo)
          }).then(response => response.json())
            .then(data => {
              
              axios
                .put(this.url+"/zones/"+this.zone_id, {
                  img: data.url,
                  sizeX:700,
                  sizeY:500,
                  name: this.zone_name,
                  category: this.zoneCategory,
                  projectId:+this.idp,
                })
                .then((resp) => {
                  let data = resp.data;
                  this.zone_id = data.id;
                  this.suspend = 1;
                  this.$router.push({ path: `/editZone/${data.id}` })
                  Bulma().alert({
                      type: 'success',
                      title: 'Confirmado',
                      body: 'La Zona se guardó exitosamanete.',
                      confirm: {
                          label: 'OK'
                      },
                  });
                })
                .catch((err) => {
                  console.log("ERROR", err);
                  Bulma().alert({
                    type: 'warning',
                    title: 'Error 400',
                    body: 'Hubo un problema al subir la foto',
                    confirm: 'Ok!',
                });
                });
            })
        .catch(err => console.log(err));    
      }else{
        Bulma().alert({
        type: 'warning',
        title: 'Aviso',
        body: 'Debe ingresar todos los datos para la zona',
        confirm: 'Ok!',
    });
      }
    },
    delete_device(id) {
      const device_id = id;
      const y = this;
      Bulma().alert({
      type: 'danger',
      title: 'Borrar Dispositivo',
      body: 'Seguro que quiere borrar el Dispositivo?',
      confirm: {
      label: 'Confirmar',
      onClick: function(){
        axios.delete(y.url+`/devices/${device_id}`).then(() => {
          axios
          .get(y.url+"/zones/"+y.zone_id+"/devices")
          .then((data) => data.data)
          .then((data) => y.devices = data);
      }).catch((err) => {
          console.log("ERROR", err);
          this.errorMsg = "Vuelva a intentarlo";
      });
      }
    },
    cancel: 'Cancelar'
});
    },
    save_device(){
      
      if(this.zone_id != ""){
        axios
          .post(this.url+"/devices", {
            name: this.form.name,
            type: this.form.type,
            can: this.form.can,
            pin: this.form.pin,
            xPosition:+this.x,
            yPosition:+this.y,
            category: this.form.cat,
            zoneId:+this.zone_id
          })
          .then(() => {
           axios
          .get(this.url+"/zones/"+this.zone_id+"/devices")
          .then((data) => data.data)
          .then((data) => this.devices = data);
          this.form.name="";
          this.form.cat="";
          this.form.type="";
          this.form.can="";
          this.form.pin="";
          this.form.xy="";
            Bulma().alert({
                type: 'success',
                title: 'Confirmado',
                body: 'El dispositivo se guardó exitosamanete.',
                confirm: {
                    label: 'OK'
                },
            });
          this.modal();
          })
          .catch((err) => {
            console.log("ERROR", err);
          });
      }
    },
    delete_img(){
      this.previewImage = "/img/AdjuntarFull.png"
    },
    modal() {
      this.toggleModal = !this.toggleModal;
    },
    route(){
      
      this.$router.push(`/editProject/${this.idp}`)
    },
    selectImage () {
          this.$refs.fileInput.click()
      },
      pickFile () {
        let input = this.$refs.fileInput
        let file = input.files
        if (file && file[0]) {
          let reader = new FileReader
          reader.onload = e => {
            this.previewImage = e.target.result
          }
          reader.readAsDataURL(file[0])
          this.$emit('input', file[0])
        }
      },
      onClickImage(event) {
        var box = document.getElementById("box");
        var e = event || window.event;
        var x1=Math.trunc((e.layerX*100)/box.offsetWidth);
        var y1=Math.trunc((e.layerY*100)/box.offsetHeight); 
        this.x = x1;
        this.y = y1;
        this.form.xy = "("+x1+","+y1+")"
      },
  },
  mounted() {
   
    axios
      .get(this.url+"/zones/"+this.zone_id+"/devices")
      .then((res) => {
        const data = res.data;
        this.devices = data;
      });
    
     axios
      .get(this.url+"/zones/"+this.zid)
      .then((res) => {
        const data = res.data;
        this.zone_name = data.name;
        this.previewImage = data.img;
        this.zoneCategory = data.category
      });
  },
};
</script>

<style scoped>
#scroll-table {
  max-height: 60vh;
  overflow-y: scroll;
}
</style>
