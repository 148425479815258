<template>
  <div class="userFormDist">
    <div class="container">
      <!-- <section class="section"> -->
      <div class="columns is-centered">
        <div class="column is-8 table-container">
          <div class="has-background-grey-lighter">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="is-size-6 is-pulled-left" >Nombre</label>
                  <div class="control">
                    <input type="text" class="input" name="name" v-model="form.name" required />
                  </div>
                </div>

                <div class="field">
                  <label class="is-size-6 is-pulled-left">Correo</label>
                  <input type="mail" class="input" name="mail" v-model="form.mail" required />
                </div>
              </div>

              <div class="column">
                <div class="field">
                  <label class="is-size-6 is-pulled-left">Contraseña</label>
                  <input type="password" class="input" name="pass" v-model="form.pass" required />
                </div>
                <div class="field">
                  <label class="is-size-6 is-pulled-left"
                    >Confirmar Contraseña</label
                  >
                  <input type="password" class="input" name="pass_conf" v-model="form.conf_pass" required />
                </div>

                
                <div class="field level is-mobile">
                  <div class="level-item">
                    <input
                      class="button is-success"
                      type="submit"
                      value="Registrar"
                      @click="save_user()"
                    />
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </section> -->
      
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Bulma from "@vizuaalog/bulmajs/src/plugins/alert"

export default {
  components: {
},
  name: "UserForm",

  data: function () {
    return {
      form:{name:"",mail:"",enable:true,pass:"",conf_pass:""},
      url:this.BaseURL,
      user_id:"",
    };
  },
  methods: {
    save_user: function () {
      let name = this.form.name;
      let mail = this.form.mail;
      let type = "distributor";
      let pass = this.form.pass;
      let pass_conf = this.form.conf_pass;

      if(pass === pass_conf){
        if (name != "" && mail !== "") {
        axios
          .post(this.url+"/users", {
            name: name,
            mail:mail,
            password:pass,
            type:type,
          })
          .then(() => {
            Bulma().alert({
                type: 'success',
                title: 'Error',
                body: 'Se guardo el usuario correctamente',
                confirm: {
                    label: 'OK'
                },
            });
            this.$router.push(`/Projects`)
          })
          .catch((err) => {
            console.log("ERROR", err);
          });  
      }else{
         Bulma().alert({
                type: 'warning',
                title: 'Error',
                body: 'Debe llenar todos los campos.',
                confirm: {
                    label: 'OK'
                },
            });
      }
    }else{
       Bulma().alert({
                type: 'warning',
                title: 'Error',
                body: 'La contrasena no coincide.',
                confirm: {
                    label: 'OK'
                },
            });
    }  
    },
  },
  mounted() {
    
  },
};
</script>
<style scoped>

</style>
