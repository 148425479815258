<template>
  <div class="newZone">
    <div class="container">
      <section class="hero is-fullheight">
        <div class="hero-body pt-4">
          <div class="container">
            <div class="box has-background-white-ter">
              <div class="box has-background-grey-lighter">
                <zoneForm />
              </div>
              <div class="modal">
                <div class="modal-background"></div>
                <div class="modal-content">
                  <!-- Any other Bulma elements you want -->
                </div>
                <button
                  class="modal-close is-large"
                  aria-label="close"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import zoneForm from "@/components/zoneForm.vue";
export default {
  components: {
    zoneForm,
  },
  data: function () {
    return {
      menu: false,
    };
  },
 
};
</script>

<style scoped>
.newZone {
  background-color: #0067e7;
}
</style>
