<template>
  <div class="newUser">
    <div class="container">
      <section class="hero is-fullheight">
        <div class="hero-body pt-4">
          <div class="container">
            <!-- <figure class="image is-48x48 is-pulled-right pt-2 pr-2">
              <img src="/img/Flecha.png" @click="route()"/>
            </figure> -->
            <div class="section box has-background-white-ter pl-6 pr-6 pb-2">
              <div class="section box has-background-grey-lighter pt-4">
                <div class="columns is-mobile">
                  <div class="column">
                    <div class="level">
                      <div class="media-left">
                        <figure class="image is-128x128">
                          <img src="/img/RegistroUsuario.png" />
                        </figure>
                      </div>
                      <div class="media-content">
                        <p class="title is-4 mt-6 is-pulled-left">
                          Registro de Usuarios Temporales
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <userFormTemp />
              </div>
              
                <div class="columns">
                <div class="column">
                  <button
                    class="button is-warning mr-6 pr-4 is-pulled-left"
                    @click="close()"
                  >
                    Cerrar Sesion
                  </button>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import userFormTemp from "@/components/userFormTemp.vue";



export default {
  components: {
    userFormTemp,
  },
  data: function () {
    return {
      menu: false,
    };
  },
  methods:{
      close: function(){
      localStorage.clear();
      this.$router.push('/')
    }
  }
};
</script>

<style scoped>
.newUser {
  background-color: #0067e7;
}
</style>
