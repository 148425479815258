<template>
  <div class="newDistributor">
    <div class="container">
      <section class="hero is-fullheight">
        <div class="hero-body pt-4">
          <div class="container">
            <div class="section box has-background-white-ter pl-6 pr-6 pb-2">
              <div class="section box has-background-grey-lighter pt-4">
                <div class="columns is-mobile">
                  <div class="column">
                    <div class="level">
                      <div class="media-left">
                        <figure class="image is-128x128">
                          <img src="/img/RegistroUsuario.png" />
                        </figure>
                      </div>
                      <div class="media-content">
                        <p class="title is-4 mt-6 is-pulled-left">
                          Registro de Distribuidor
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <userFormDist />
              </div>
              <figure class="image is-48x48">
                <img src="/img/Flecha.png" @click="route()"/>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import userFormDist from "@/components/userFormDist.vue";

export default {
  components: {
    userFormDist,
  },
  data: function () {
    return {
      menu: false,
      url: this.BaseURL,
    };
  },
  methods:{
    route(){
      this.$router.push(`/Projects`)
    }
  }
};
</script>

<style scoped>
.newDistributor {
  background-color: #0067e7;
}
</style>
