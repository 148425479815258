<template>
  <div class="projectForm">
    <div class="container">
      <!-- <section class="section"> -->
      <div class="columns is-centered">
        <div class="column is-8 table-container">
          <div class="has-background-grey-lighter">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="is-size-6 is-pulled-left">Nombre</label>
                  <div class="control">
                    <input type="text" class="input" name="name" required v-model="form.name" />
                  </div>
                </div>

                <div class="field">
                  <label class="is-size-6 is-pulled-left">MQTTPath</label>
                  <input type="text" class="input" name="mqttpath" required v-model="form.mqttpath"/>
                </div>

                <div class="field" v-if="type != 'distributor'" v-bind:class="{ 'is-hidden' : type == 'distributor'}">
                  <label class="is-size-6 is-pulled-left"
                    >Desactivar/Activar</label>
                  <br />
                  <label class="switch is-rounded is-medium is-pulled-left">
                    <input type="checkbox" value="true" checked v-model="form.enable"/>
                    <span class="check is-info"></span>
                  </label>
                </div>
                <br>
                <div class="field">
                      <input
                        class="button is-info is-pulled-left"
                        :disabled= "!!!device_id"
                        type="submit"
                        value="Ver Usuarios"
                        @click="modal3()"
                      />
                    </div>
              </div>

              <div class="column">
                <div class="field">
                  <label class="is-size-6 is-pulled-left">Usuario</label>
                  <input type="text" class="input  has-text-grey" name="user" readonly v-model="user"/>
                </div>
                <br />

                <div class="field level is-mobile">
                  <div class="level-right">
                    <div class="level-item">
                      <input
                        class="button is-info"
                        :disabled= "!!!project_id"
                        type="submit"
                        value="Zonas"
                        @click="modal()"
                      />
                    </div>
                    <div class="level-item">
                      <figure class="image is-32x32">
                        <img src="/img/PalomitaVerde.png" alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div class="field level is-mobile">
                  <div class="level-right">
                    <div class="level-item">
                      <input
                        class="button is-info"
                        :disabled= "!!!device_id"
                        type="submit"
                        value="Dispositivos"
                        @click="modal2()"
                      />
                    </div>
                    <div class="level-item">
                      <figure class="image is-32x32">
                        <img src="/img/SignoInterrogacion.png" alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div class="field level is-mobile" v-if="type != 'distributor'" v-bind:class="{ 'is-hidden' : type == 'distributor'}">
                  <div class="level-right">
                    <div class="level-item">
                      <input
                        class="button is-info"
                        :disabled= "!!!device_id"
                        type="submit"
                        value="Usuarios"
                        @click="$router.push('/newUser')"
                      />
                    </div>
                    <div class="level-item">
                      <figure class="image is-32x32">
                        <img src="/img/Candado.png" alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                
                
              </div>
              
            </div>
             <div class="field">
                  <div class="control">
                    <input
                        class="button is-success"
                        type="submit"
                        value="Guardar Proyecto"
                        @click="update_proyect()"
                      />
                  </div>
                </div>
          </div>
          
        </div>
        
      </div>
      <div class="modal" :class="{ 'is-active': toggleModal }">
        <div class="modal-background" @click="modal()"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title"></p>
            <button class="delete" aria-label="close" @click="modal()"></button>
          </header>
          <section class="modal-card-body">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <figure class="image is-96x96">
                    <img src="/img/Zonas.png" alt="" />
                  </figure>
                </div>
                <div class="level-item">
                  <h1 class="subtitle is-4">Zonas</h1>
                </div>
              </div>
            </div>

            <div class="box has-background-grey-lighter" id="scroll-table">
              <table
                class="table is-fullwidth has-background-white-ter is-hoverable"
              >
                <tbody v-for="zone in zones" :key="zone.name">
                  <tr>
                    <td>
                      <!-- <h1 >{{ proyect.name }}</h1> -->
                      <h1 class="subtitle is-5 is-pulled-left">
                        {{ zone.name }}
                      </h1>
                    </td>
                    <td>
                      <figure class="image is-32x32 is-pulled-right">
                        <!-- <p class="image is-32x32 "> -->
                        
                          <img src="/img/Editar.png" @click="route(zone.id)"/>
                        
                        <!-- </p> -->
                      </figure>
                    </td>
                    <td>
                      <figure class="image is-32x32">
                        <!-- <p class="image is-32x32"> -->
                        <img src="/img/Equis.png" @click="delete_zone(zone.id)"/>
                        <!-- </p> -->
                      </figure>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-info" @click="$router.push('/newZone')">
              Nueva Zona
            </button>
            <button class="button" @click="modal">Cancelar</button>
          </footer>
        </div>
      </div>

      <div class="modal" :class="{ 'is-active': toggleModal2 }">
        <div class="modal-background" @click="modal2()"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Dispositivos</p>
            <button
              class="delete"
              aria-label="close"
              @click="modal2()"
            ></button>
          </header>
          <section class="modal-card-body">
            <!-- <h1 class="subtitle">Dispositivos</h1>   -->

            <div class="box has-background-grey-light" id="scroll-table">
              <table
                class="table is-fullwidth has-background-white-ter is-hoverable"
              >
                <tbody
                  v-for="device in devices"
                  :key="device.name"
                  
                >
                  <tr>
                    <td>
                      <!-- <h1 >{{ proyect.name }}</h1> -->
                      <h1 class="subtitle is-6 is-pulled-left">
                        {{ device.name }}
                      </h1>
                    </td>
                    <!-- <td>
                      <figure class="image is-24x24 is-pulled-right">
                        <router-link to="">
                          <img src="/img/Editar.png" />
                        </router-link>
                      </figure>
                    </td> -->
                    <td>
                      <figure class="image is-24x24">
                        <!-- <p class="image is-32x32"> -->
                        <img src="/img/Equis.png" @click="delete_device(device.id)"/>
                        <!-- </p> -->
                      </figure>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>


      <div class="modal" :class="{ 'is-active': toggleModal3 }">
        <div class="modal-background" @click="modal3()"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Usuarios Registrados</p>
            <button
              class="delete"
              aria-label="close"
              @click="modal3()"
            ></button>
          </header>
          <section class="modal-card-body">
            <div class="box has-background-grey-light" id="scroll-table">
              <table
                class="table is-fullwidth has-background-white-ter is-hoverable"
              >
              <thead>
                <tr>
                  <th>Correo</th>
                  <th>Password</th>
                  <th>Tipo</th>
                  <th>Borrar</th>
                </tr>
              </thead>
                <tbody
                  v-for="user in users"
                  :key="user.name"
                  
                >
                  <tr>
                    <td v-if="user.type !='admin'">
                      <h1 class="subtitle is-6 is-pulled-left">
                        {{ user.mail }}
                      </h1>
                    </td>
                    <td v-if="user.type !='admin'">
                      <h1 class="subtitle is-6 is-pulled-left">
                        {{ user.password }}
                      </h1>
                    </td>
                    <td v-if="user.type !='admin'">
                      <h1 class="subtitle is-6 is-pulled-left">
                        {{ user.type }}
                      </h1>
                    </td>
                    <td v-if="user.type !='admin'">
                      <figure class="image is-24x24 ml-6">
                        <img src="/img/Equis.png" @click="delete_user(user.id)"/>
                      </figure>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
      <!-- </section> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Bulma from "@vizuaalog/bulmajs/src/plugins/alert"

export default {
  name: "EditProject",
  props: ["test"],

  data() {
    return {
      form: {name:"", mqttpath:"", enable:true, user:""},
      toggleModal: false,
      toggleModal2: false,
      toggleModal3: false,
      zones: [],
      devices: [],
      users:[],
      project_id: this.test,
      device_id: 1,
      zid:"",
      user: "",
      type: "",
      url: this.BaseURL,
    };
  },
  methods: {
    modal() {
      this.toggleModal = !this.toggleModal;
    },
    modal2() {
      this.toggleModal2 = !this.toggleModal2;
    },
    modal3() {
      this.toggleModal3 = !this.toggleModal3;
    },
     update_proyect: function () {
      let name = this.form.name;
      let mqttpath = this.form.mqttpath;
      let enable = this.form.enable;
      
     
      if (name != "" && mqttpath !== "") {
        axios
          .put(this.url+`/projects/${this.test}`, {
            name: name,
            mqttPath: mqttpath,
            enabled: enable,
          })
          .then((resp) => {
            let data = resp.data;
            this.project_id = data.id;
            this.get_zones(this.project_id);
            Bulma().alert({
                type: 'success',
                title: 'Confirmado',
                body: 'El proyecto se guardó exitosamanete.',
                confirm: {
                    label: 'OK'
                },
            });
          })
          .catch((err) => {
            console.log("ERROR", err);
          });
        
      }
    },
      delete_zone(id) {
      const zone_id = id;
      const y = this;
      Bulma().alert({
      type: 'warning',
      title: 'Borrar Zona',
      body: 'Seguro que quiere borrar la Zona?',
      confirm: {
      label: 'Confirmar',
      onClick: function(){
        axios.delete(y.url+`/zones/${zone_id}`).then(() => {
          axios
          .get(y.url+"/zones")
          .then((data) => data.data)
          .then((data) => y.zones = data);
      }).catch(() => {
        Bulma().alert({
        type: 'danger',
        title: 'Error 500',
        body: 'Algo ah fallado!',
        confirm: 'Ok!',
      
    });
            
          });
      }
    },
    cancel: 'Cancelar'
});
    },
     route(zid){
      
      this.$router.push(`/editZone/${zid}`)
    },
      delete_device(id) {
      const device_id = id;
      const y = this;
      Bulma().alert({
      type: 'danger',
      title: 'Borrar Dispositivo',
      body: 'Seguro que quiere borrar el Dispositivo?',
      confirm: {
      label: 'Confirmar',
      onClick: function(){
        axios.delete(y.url+`/devices/${device_id}`).then(() => {
          axios
          .get(y.url+"/devices")
          .then((data) => data.data)
          .then((data) => y.devices = data);
      }).catch((err) => {
          console.log("ERROR", err);
          this.errorMsg = "Vuelva a intentarlo";
      });;
      }
    },
    cancel: 'Cancelar'
});
    },
    delete_user(id){
      const user_id = id;
      const y = this;
      Bulma().alert({
      type: 'danger',
      title: 'Borrar Usuario',
      body: 'Seguro que quiere borrar el Usuario?',
      confirm: {
      label: 'Confirmar',
      onClick: function(){
        axios.delete(y.url+`/users/${user_id}`).then(() => {
          axios
          .get(y.url+"/projects/"+y.test+"/users")
          .then((data) => data.data)
          .then((data) => y.users = data);
      }).catch((err) => {
          console.log("ERROR", err);
          this.errorMsg = "Vuelva a intentarlo";
      });;
      }
    },
    cancel: 'Cancelar'
});
    },
    get_zones(project_id){
       axios.get(this.url+`/projects/${project_id}/zones`)
      .then((res) => res)
      .then((res) => {
        const data = res.data
        this.zones = data;
      });
    }
  },
    mounted() {
      localStorage.setItem("idp",this.test);
      if(this.test){
        axios
        .get(this.url+"/projects/"+this.test)
        .then((res) => res)
        .then((res) => {
          const name = res.data
          this.form.mqttpath = name.mqttPath
          this.form.name = name.name;
          this.form.enable = name.enabled
          
        }); 
      }

      axios
      .get(this.url+"/users/detail")
      .then((res) => res)
      .then((res) => {
        const name = res.data
        this.user = name.mail
        this.type = name.type
        
      });

      axios
      .get(this.url+"/projects/"+this.test+"/devices")
      .then((res) => {
        const data = res.data
        this.devices = data
      
      });

      axios
      .get(this.url+"/projects/"+this.test+"/zones")
      .then((res) => {
        const data = res.data
        this.zones = data
       
      });

      axios
      .get(this.url+"/projects/"+this.test+"/users")
      .then((res) => {
        const data = res.data
        this.users = data
      });

      
  },
};
</script>

<style scoped>
#scroll-table {
  max-height: 50vh;
  overflow-y: scroll;
}

</style>
