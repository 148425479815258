<template>
  <div class="userForm">
    <div class="container">
      <!-- <section class="section"> -->
      <div class="columns is-centered">
        <div class="column is-8 table-container">
          <div class="has-background-grey-lighter" id="scroll-table">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="is-size-6 is-pulled-left" >Nombre</label>
                  <div class="control">
                    <input type="text" class="input" name="name" v-model="form.name" required />
                  </div>
                </div>

                <div class="field">
                  <label class="is-size-6 is-pulled-left">Correo</label>
                  <input type="mail" class="input" name="mail" v-model="form.mail" required />
                </div>

                <div class="field">
                  <label class="is-size-6 is-pulled-left">Asignar Tiempo</label>
                  <br />
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <figure class="image is-32x32">
                          <img src="/img/Calendario.png" alt="" />
                        </figure>
                      </div>
                      <Datepicker v-model="date" format="YYYY-MM-DD" class="input is-small" />          
                    </div>
                  </div>
                </div>

                <!-- <div class="field">
                  <label class="is-size-6 is-pulled-left">OFF/ON</label>
                  <br />
                  <label class="switch is-rounded is-medium is-pulled-left">
                    <input type="checkbox" value="true" checked v-model="form.enable"/>
                    <span class="check is-info"></span>
                  </label>
                </div> -->
                
                <div class="field">
                  <label class="is-size-6 is-pulled-left">Tipo</label>
                  <input type="text" class="input  has-text-grey" name="pass" v-model="form.type" readonly  />
                </div>
              </div>

              <div class="column">
                <div class="field">
                  <label class="is-size-6 is-pulled-left">Contraseña</label>
                  <input type="password" class="input" name="pass" v-model="form.pass" required />
                </div>
                <div class="field">
                  <label class="is-size-6 is-pulled-left"
                    >Confirmar Contraseña</label
                  >
                  <input type="password" class="input" name="pass_conf" v-model="form.conf_pass" required />
                </div>

                <div class="field">
                  <label class="is-size-6 is-pulled-left">Proyecto</label>
                  <input type="text" class="input has-text-grey" name="project" v-model="project" readonly />
                </div>
                <div class="field level is-mobile">
                  <!-- <div class="level-right"> -->
                  <div class="level-item">
                    <input class="button is-link" type="submit" value="Agregar Zonas" @click="modal()"/>
                    <!-- </div> -->
                  </div>
                </div>
                <div class="field level is-mobile">
                  <!-- <div class="level-right"> -->
                  <div class="level-item">
                    <input
                      class="button is-success"
                      type="submit"
                      value="Registrar Usuario"
                      @click="save_user()"
                    />
                    <!-- </div> -->
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </section> -->
      <div class="modal" :class="{ 'is-active': toggleModal }">
        <div class="modal-background" @click="modal()"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title"></p>
            <button class="delete" aria-label="close" @click="modal()"></button>
          </header>
          <section class="modal-card-body">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <figure class="image is-96x96">
                    <img src="/img/Zonas.png" alt="" />
                  </figure>
                </div>
                <div class="level-item">
                  <h1 class="subtitle is-4">Zonas</h1>
                </div>
              </div>
            </div>

            <div class="box has-background-grey-lighter" id="scroll-table">
              <table
                class="table is-fullwidth has-background-white-ter is-hoverable"
              >
                <tbody v-for="zone in zones" :key="zone.name">
                  <tr>
                    <td>
                      <!-- <h1 >{{ proyect.name }}</h1> -->
                      <h1 class="subtitle is-5 is-pulled-left">
                        {{ zone.name }}
                      </h1>
                    </td>
                    
                    <td>
                        <label class="switch is-rounded is-medium ">
                          <input type="checkbox" :id="zone.id" v-model="enabled_zone" :value="zone.id"/>
                          <span class="check is-success"></span>
                        </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Datepicker from 'vue3-datepicker'
import Bulma from "@vizuaalog/bulmajs/src/plugins/alert"
import { format } from 'date-fns'


export default {
  components: {
  Datepicker,
  
},
  name: "UserForm",

  data () {
    return {
      form:{name:"",mail:"",enable:true,type:"Usuario",pass:"",conf_pass:""},
      project:0,
      zones:[],
      date: new Date(),
      toggleModal: false,
      enabled_zone: [],
      user_id:"",
      url: this.BaseURL,
    };
  },
  methods: {
    modal() {
      this.toggleModal = !this.toggleModal;
    },
    save_user: function () {
      const idp = +localStorage.getItem('idpu')
      let name = this.form.name;
      let mail = this.form.mail;
      let type = "user";
      let pass = this.form.pass;
      let pass_conf = this.form.conf_pass;
      let dates = format(this.date,'yyyy-MM-dd')

      if(pass === pass_conf){
        if (name != "" && mail !== "") {
        axios
          .post(this.url+"/users", {
            name: name,
            mail:mail,
            password:pass,
            type:type,
          })
          .then((resp) => {
            let data = resp.data;
            this.user_id = data.id;
            axios.put(this.url+"/users/"+this.user_id,{
              zones:this.zones,
              timeAvilable:dates,
              projects:[idp]})
              .then(() => {
                this.form.name="";
                this.form.mail="";
                this.form.pass="";
                this.form.conf_pass="";

                Bulma().alert({
                type: 'success',
                title: 'Registro Exitoso',
                body: 'Se guardo el usuario correctamente.',
                confirm: {
                    label: 'OK'
                },
            });
            })
          })
          .catch((err) => {
            console.log("ERROR", err);
          });  
      }else{
        Bulma().alert({
                type: 'warning',
                title: 'Error',
                body: 'Debe llenar todos los campos.',
                confirm: {
                    label: 'OK'
                },
            });
      }
    }else{
       Bulma().alert({
                type: 'warning',
                title: 'Error',
                body: 'La contrasena no coincide.',
                confirm: {
                    label: 'OK'
                },
            });
    }  
    },
  },
  mounted() {
    const idp = localStorage.getItem('idpu')
    
      axios
      .get(this.url+"/projects/"+idp)
      .then((res) => {
        const data = res.data;
        this.project = data.name;
      });
      
      axios
      .get(this.url+"/projects/"+idp+"/zones")
      .then((res) => {
        const data = res.data;
        this.zones = data;
      });
  },
};
</script>
<style scoped>
#scroll-table {
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

</style>
