<template>
  <div class="editProject">
  
    <div class="container">
      <section class="hero is-fullheight">
        <div class="hero-body pt-4">
          <div class="container">
            <!-- <figure class="image is-48x48 is-pulled-right pt-2 pr-2">
              <router-link to="/projects"
                ><img src="/img/Flecha.png"
              /></router-link>
            </figure> -->
            <div class="section box has-background-white-ter pl-6 pr-6 pb-2">
              <div class="section box has-background-grey-lighter pt-4 pb-2">
                <div class="columns is-mobile">
                  <div class="column">
                    <div class="level">
                      <div class="media-left">
                        <figure class="image is-128x128">
                          <img src="/img/Registro.png" />
                        </figure>
                      </div>
                      <div class="media-content">
                        <p class="title is-4 mt-6 is-pulled-left">Registro</p>
                      </div>
                    </div>
                  </div>
                </div>
                <editProjectForm :test="$route.params.id" />
              </div>
              <figure class="image is-48x48">
                <router-link to="/projects"
                  ><img src="/img/Flecha.png"
                /></router-link>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

import editProjectForm from "@/components/editProjectForm.vue";

export default {
  components: {
    editProjectForm,
  },
  mounted(){
    
  }
};

</script>

<style scoped>
.editProject {
  background-color: #0067e7;
}

</style>
